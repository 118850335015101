import { CContainer, CRow, CSpinner } from "@coreui/react-pro";

export const AppLoader = () => (
  <div className="bg-light h-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow className="justify-content-center">
        <CSpinner color="primary" />
      </CRow>
    </CContainer>
  </div>
);

export const Loader = () => (
  <div className="d-flex flex-row align-items-center">
    <CContainer>
      <CRow className="justify-content-center">
        <CSpinner color="primary" />
      </CRow>
    </CContainer>
  </div>
);
